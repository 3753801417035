// ЗАКАЖИТЕ БЕСПЛАТНУЮ КОНСУЛЬТАЦИЮ
function submitEmail2(params) {
  let str = window.atob("aHR0cHM6Ly9vb2NjbWFpbC5ydS9zZW5kP3Rva2VuPXZqOTgzNGhnMmhmNzg5aDIzNGZoNHVmaDM3OGZoMw==");

  fetch(`${str}`, {
    credentials: 'same-origin',
    mode:'no-cors',
    method: 'POST',

    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
    },

    body: `naimenovanieGruza=${params.naimenovanieGruza}&obshchijVes=${params.obshchijVes}&obshchijObem=${params.obshchijObem}&kolichestvoMest=${params.kolichestvoMest}&dlinaGruza=${params.dlinaGruza}&shirinaGruza=${params.shirinaGruza}&vysotGruza=${params.vysotGruza}&primechaniyaZayavke=${params.primechaniyaZayavke}&izgotovlenieUpakovki=${params.izgotovlenieUpakovki}&strahovanieGruza=${params.strahovanieGruza}&skladskoeHranenie=${params.skladskoeHranenie}&dataOtgruzki=${params.dataOtgruzki}&gorodOtpravitel=${params.gorodOtpravitel}&gorodPoluchatel=${params.gorodPoluchatel}&vasheImya=${params.vasheImya}&telefon=${params.telefon}`
  })
  .catch(error => console.log(error))
}

function validaionForm2(e, form) {
  e.preventDefault();

  let naimenovanieGruza = form.children[0][0].value;
  let obshchijVes = form.children[0][1].value;
  let obshchijObem = form.children[0][2].value;
  let kolichestvoMest = form.children[0][3].value;
  let dlinaGruza = form.children[0][4].value;
  let shirinaGruza = form.children[0][5].value;
  let vysotGruza = form.children[0][6].value;
  let primechaniyaZayavke = form.children[0][7].value;

  let izgotovlenieUpakovki = form.children[0][8].checked;
  let strahovanieGruza = form.children[0][9].checked;
  let skladskoeHranenie = form.children[0][10].checked;

  let dataOtgruzki = form.children[0][11].value;
  let gorodOtpravitel = form.children[0][12].value;
  let gorodPoluchatel = form.children[0][13].value;
  let vasheImya = form.children[0][14].value;
  let telefon = form.children[0][15].value;

  submitEmail2({ 
    naimenovanieGruza: naimenovanieGruza, 
    obshchijVes: obshchijVes,
    obshchijObem: obshchijObem ,
    kolichestvoMest: kolichestvoMest,
    dlinaGruza: dlinaGruza,
    shirinaGruza: shirinaGruza,
    vysotGruza: vysotGruza,
    vysotGruza: vysotGruza,
    primechaniyaZayavke: primechaniyaZayavke,
    izgotovlenieUpakovki: izgotovlenieUpakovki,
    strahovanieGruza: strahovanieGruza,
    skladskoeHranenie: skladskoeHranenie,
    dataOtgruzki: dataOtgruzki,
    gorodOtpravitel: gorodOtpravitel,
    gorodPoluchatel: gorodPoluchatel,
    vasheImya: vasheImya,
    telefon: telefon
  });

  form.children[0][0].value = "";
  form.children[0][1].value = "";
  form.children[0][2].value = "";
  form.children[0][3].value = "";
  form.children[0][4].value = "";
  form.children[0][5].value = "";
  form.children[0][6].value = "";
  form.children[0][7].value = "";
  form.children[0][8].checked = false;
  form.children[0][9].checked = false;
  form.children[0][10].checked = false;
  form.children[0][11].value = "";
  form.children[0][12].value = "";
  form.children[0][13].value = "";
  form.children[0][14].value = "";
  form.children[0][15].value = "";

  let modal = document.querySelector("#zayavka");
  modal.style.display = "none";

  alert( "Успешно отправлено!" );
}

// modal form
let modalForm2 = document.querySelector('#zayavka');
modalForm2.addEventListener('submit', e => validaionForm2(e, modalForm2));

